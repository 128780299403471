/**
 * 以下是接口地址链接
 * @type {string}
 */
const DevPath = "/api";
const ProdPath = "https://pay.octabox.cn";
const TestPath = "http://st365new.kycloud.cn";
const PrePath = "http://octanew.kycloud.cn";

/**
 * 以下是打开移动端地址的链接（非接口地址链接）
 * @type {string}
 */
const DevMobile = 'http://wxaimobile.kycloud.cn';
const ProdMobile = 'http://wxaimobile.kycloud.cn';
const TestMobile = 'http://wxaimobile.kycloud.cn';
const PreMobile = 'http://wxaimobile.kycloud.cn';


export const BaseParam = {
    // 接口地址
    fetchUrl:
        process.env.REACT_APP_ENV == "development" ? DevPath :
            process.env.REACT_APP_ENV == "production" ? ProdPath :
                process.env.REACT_APP_ENV == "test" ? TestPath :
                    process.env.REACT_APP_ENV == "pre" ? PrePath : DevPath,
    // 外链 （非接口请求URL）
    mobileUrl:
        process.env.REACT_APP_ENV == "development" ? DevMobile :
            process.env.REACT_APP_ENV == "production" ? ProdMobile :
                process.env.REACT_APP_ENV == "test" ? TestMobile :
                    process.env.REACT_APP_ENV == "pre" ? PreMobile : DevMobile,

    ossUrl:'https://lvpingtai.oss-cn-hangzhou.aliyuncs.com'
};