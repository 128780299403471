import './common/home-reset.css';
import React from "react";
import {Router, Route, Switch, Redirect} from 'react-router-dom';
// import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import u_Base from "./utils/u_Base";
import u_History from "./utils/u_History";

// 组件
import {routerRoot} from "./router/config";
import ScrollToTop from "./components/ScrollToTop";

class App extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // 无论什么链接第一次进入判断cookie中是否存在用户信息
        // 有的话不做判断
        // 没有的话跳转到首页
        // TODO 如果cookie中有用户信息就进入此平台 后面操作不走此方法!!!
        // if (u_Type.isFalse(u_Cookie.get())) {
        //     u_History.push('/')
        // }
        document.title = "云南省卒中学会"
    }

    render() {
        return (
            <div className='app'>
                <Router basename={u_Base.BASENAME} history={u_History}>
                    <Switch>
                        <ScrollToTop>
                            {
                                routerRoot.map((e, i) => {
                                    if (i == 0)
                                        return (
                                            <Route exact key={i} path={e.path} component={e.name}/>
                                        )
                                    else
                                        return (
                                            <Route key={i} path={e.path} component={e.name}/>
                                        )
                                })
                            }
                            {/*<Redirect from="/*" to="/"/>*/}
                        </ScrollToTop>
                    </Switch>
                </Router>
            </div>
        )
    }
}


export default App;
