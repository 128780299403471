import React from "react";
import ReactDOM from "react-dom";
import {Provider} from 'react-redux'

import store from './store/index'
import locale from 'antd/lib/locale/zh_CN';
import { ConfigProvider } from 'antd'
import App from "./App";
// 引入antd样式
import 'antd/dist/antd.css'
// 全局样式
import './common/app.less'

import {domain} from './utils/u_Origin'
domain();

// 引入阿里验证js资源
(function () {
    window.NVC_Opt = {
      appkey: 'FFFF0N00000000008824',
      scene: 'ic_message'
    }
   
    const afs = document.createElement("script");
  
    let date = new Date();
    let year = date.getFullYear().toString();
    let month = (date.getMonth() + 1).toString();
    let day = date.getDay().toString();
    let hour = date.getHours().toString();
    if(month < 10) {
      month = '0' + month
    }
    if(day < 10) {
      day = '0' + day
    }
  
    afs.src = `//g.alicdn.com/sd/nvc/1.1.112/guide.js?t=${year + month + day + hour}`;
    const s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(afs, s);
  })();

setTimeout(()=>{
    let rootRender = <Provider store={store}>
        <ConfigProvider locale={locale}>
            <App/>
        </ConfigProvider>
    </Provider>

// 跨域响应事件
    window.addEventListener('message',function(event) {
        if(event.origin !== 'http://127.0.0.1:5500') return;
        // 把token存入缓存中cookie
    },false);

    ReactDOM.render(
        rootRender,
        document.getElementById("root")
    );
},200);
