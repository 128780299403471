import './style.less';
import React from 'react'
import {Spin, Alert} from 'antd';

class Loading extends React.Component {
    render() {
        return (
            <div className="loading_example">
                <Spin size="large" />
            </div>
        )
    }
}

export default Loading
