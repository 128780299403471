import {GetTenantByDomain} from "../api/App";
import u_Cookie from "./u_Cookie";
import u_Base from './u_Base'
import {message} from 'antd';

export const domain = async () => {
    const response = await GetTenantByDomain({domain: window.location.origin});
        // const response = await GetTenantByDomain({domain: 'http://ynczxh.kycloud.cn'});

    if (response.success) {
        u_Cookie.set('domain', response.data);

        // 如果当前浏览器是微信则跳转 移动端链接
        if (process.env.REACT_APP_ENV !== "development") {
            if (!u_Base.IsPC()) {
                if (location.href.indexOf("activityDetail") > -1) {
                    let id = window.location.href.split('/').slice('-1').toString()
                    window.location.href = response.data.mobileDomain + "activityDetail?id=" + id;
                } else {
                    window.location.href = response.data.mobileDomain;
                }
            }
        }



    } else {
        message.error(response.msg);
    }
};