import axios from "axios";
import {BaseParam} from './u_Config'
import u_Cookie from "./u_Cookie";

const instance = axios.create({
    timeout: 200000,
    baseURL: BaseParam.fetchUrl +'/st365'
});

instance.defaults.headers.post["Content-Type"] =
    "application/json;charset=UTF-8";
instance.defaults.headers["Cache-Control"]="no-cache";

// 设置http状态码
let httpCode = {
    301: "资源(网页等)被永久转移到其它URL",
    400: "请求参数错误",
    401: "权限不足, 请重新登录",
    403: "服务器拒绝本次访问",
    404: "请求资源未找到",
    500: "内部服务器错误",
    501: "服务器不支持该请求中使用的方法",
    502: "网关错误",
    504: "网关超时"
};

/** 添加请求拦截器 **/
instance.interceptors.request.use(
    config => {
        const user = u_Cookie.get(u_Cookie.DEFAULTNAME)
        if(user){
            config.headers["token"] = user.token;
        };


        const params = u_Cookie.get('domain');
        if (params){
            if (config.url !='/userLogin/logOut') {
                config.headers["tenantId"] = params.id;
            }else {
                config.headers["ischeck"] = 1;
            }
        }


        return config;
    },
    error => {
        // 请求错误调用reject返回错误信息
        return Promise.reject(error)

    }
);

/** 添加响应拦截器  **/
instance.interceptors.response.use(
    response => {
        // if (
        //     response.data.code === "repeatLogin" ||
        //     response.data.code === "notLogin"
        // ) {
        //     // 异地登录或者未登录或者登录超时
        //     message.error(
        //         response.data.code === "repeatLogin"
        //             ? "您的帐号在其它地点登录，请重新登录或联系平台管理员"
        //             : "未登录或登录超时，请重新登录"
        //     );
        //     rLogin();
        // }else if(response.data.code === 'notNotify') {
        //     rLogin();
        // }
        return Promise.resolve(response.data)
    },
    error => {
        if (error.response) {
            // 根据请求失败的http状态码去给用户相应的提示
            let tips =
                error.response.status in httpCode
                    ? httpCode[error.response.status]
                    : error.response.data.msg;
            console.log(tips);
            // if (error.response.status === 404) {
            //     // token或者登录失效情况下跳转到登录页面，根据实际情况，在这里可以根据不同的响应错误结果，做对应的事。这里我以401判断为例
            //     router.push({
            //         path: `/`
            //     });
            // }
            return Promise.reject(error);
        } else {
            console.log(error)
            return Promise.reject(new Error("请求超时, 请刷新重试"));
        }
    }
);

/* 统一封装get请求 */
export const KY_Get = (url, params) => {
    return new Promise((resolve, reject) => {
        instance({
            method: "get",
            url,
            params
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
};

/* 统一封装post请求  */
export const KY_Post = (url, data) => {
    return new Promise((resolve, reject) => {
        instance({
            method: "post",
            url,
            data
        })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
};

// const rLogin = () => {
//     window.location.href=BaseParam.fetchUrl
// }
