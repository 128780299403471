import cookie from 'react-cookies';

export default {
    DEFAULTNAME:'userInfo',
    /**
     * cookie 设置
     * @param name              名称
     * @param value             存储值
     * @param inFifteenMinutes  当前时间
     */
    set(name, value = {}, time = new Date().getTime()) {
        if (name){
            const inFifteenMinutes = new Date(time + 12 * 3600 * 1000)
            cookie.save(name, value, {
                expires: inFifteenMinutes,
                path: '/'
            });
        }else{
            console.error("key值不能为空！")
        }
    },

    get(name) {
        return cookie.load(name)
    },

    del(name) {
        const inFifteenMinutes = new Date(new Date().getTime() + 12 * 3600 * 1000)
        cookie.remove(name,{
            expires: inFifteenMinutes,
            path: '/'
        })
    },
    clearAllCookie() {
        var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
        if(keys) {
            for(var i = keys.length; i--;)
                document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
        }
    }
}