import {
    INCREMENT, DECREMENT
} from '../action/HomeAction'

let initialState = {
    countSize: 0
};

const HomeReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case INCREMENT:
            return Object.assign({}, state, {
                countSize: state.countSize + 1
            });
        case DECREMENT:
            return Object.assign({}, state, {
                countSize: state.countSize - 1
            });
        default:
            return state
    }
};

export default HomeReducer;
