import {combineReducers} from 'redux';

import HomeReducer from './reducer/HomeReducer';
import TestReducer from "./reducer/TestReducer";
const reducer = combineReducers({
    HomeReducer,
    TestReducer
});
export default reducer;


