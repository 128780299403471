//Loadable插件需使用Loading
import Loadable from 'react-loadable'
import Loading from '../components/Loading/index';

export const routerRoot = [
    {
        title: "首页",
        path: "/",
        name: Loadable({
            loader: () => import('../views/HomePage'),
            loading: Loading
        })
    },
    {
        title: "关于学会",
        path: "/dynamicabout",
        name: Loadable({
            loader: () => import('../views/About/listabout'),
            loading: Loading
        })
    },
    {
        title: "神经质控",
        path: "/listSJ",
        name: Loadable({
            loader: () => import('../views/Dynamic/listSJ'),
            loading: Loading
        })
    },
    {
        title: "学会动态",
        path: "/dynamiclist",
        name: Loadable({
            loader: () => import('../views/Dynamic/list'),
            loading: Loading
        })
    },
    {
        title: "学会动态 -- 详情",
        path: "/dynamicdetail/:id/:typeId?",
        name: Loadable({
            loader: () => import('../views/Dynamic/detail'),
            loading: Loading
        })
    },
    {
        title: "神经质控 -- 详情",
        path: "/dynamicdetailSJ/:id/:typeId?",
        name: Loadable({
            loader: () => import('../views/Dynamic/detailSJ'),
            loading: Loading
        })
    },
    {
        title: "分会之窗",
        path: "/listmem",
        name: Loadable({
            loader: () => import('../views/Dynamic/listmem'),
            loading: Loading
        })
    },
    {
        title: "分会之窗 -- 详情",
        path: "/detailmem/:id/:typeId?",
        name: Loadable({
            loader: () => import('../views/Dynamic/detailmem'),
            loading: Loading
        })
    },
    {
        title: "通知公告",
        path: "/noticeList",
        name: Loadable({
            loader: () => import('../views/Notice/list'),
            loading: Loading
        })
    },
    {
        title: "通知公告 -- 详情",
        path: "/noticeDetail/:id",
        name: Loadable({
            loader: () => import('../views/Notice/detail'),
            loading: Loading
        })
    },
    {
        title: "行业动态",
        path: "/industryList",
        name: Loadable({
            loader: () => import('../views/Industry/list'),
            loading: Loading
        })
    },
    {
        title: "行业动态 -- 详情",
        path: "/industryDetail/:id/:typeId?",
        name: Loadable({
            loader: () => import('../views/Industry/detail'),
            loading: Loading
        })
    },
    {
        title: "学会成员",
        path: "/memberList",
        name: Loadable({
            loader: () => import('../views/Member/list'),
            loading: Loading
        })
    },
    {
        title: "学会成员 -- 详情",
        path: "/memberDetail/:id",
        name: Loadable({
            loader: () => import('../views/Member/detail'),
            loading: Loading
        })
    },
    {
        title: "宣传视频",
        path: "/vedioList",
        name: Loadable({
            loader: () => import('../views/Vedio/list'),
            loading: Loading
        })
    },
    {
        title: "宣传视频 -- 详情",
        path: "/vedioDetail/:id",
        name: Loadable({
            loader: () => import('../views/Vedio/detail'),
            loading: Loading
        })
    },
    {
        title: "科技期刊",
        path: "/periodicalList",
        name: Loadable({
            loader: () => import('../views/Periodical/list'),
            loading: Loading
        })
    },
    {
        title: "关于我们",
        path: "/about",
        name: Loadable({
            loader: () => import('../views/About'),
            loading: Loading
        })
    },
    {
        title: "下载中心",
        path: "/downloadList",
        name: Loadable({
            loader: () => import('../views/Download/list'),
            loading: Loading
        })
    },
    {
        title: "下载中心 -- 详情",
        path: "/downloadDetail/:id",
        name: Loadable({
            loader: () => import('../views/Download/detail'),
            loading: Loading
        })
    },
    {
        title: "会议活动",
        path: "/activityList",
        name: Loadable({
            loader: () => import('../views/Activity/list'),
            loading: Loading
        })
    },
    {
        title: "会议活动详情",
        path: "/activityDetail/:id",
        name: Loadable({
            loader: () => import('../views/Activity/detail'),
            loading: Loading
        })
    },
    {
        title: "会议活动预览",
        path: "/activityDetailpreview",
        name: Loadable({
            loader: () => import('../views/Activity/detailpreview'),
            loading: Loading
        })
    },
    {
        title: "在线投票",
        path: "/voteList",
        name: Loadable({
            loader: () => import('../views/Vote/list'),
            loading: Loading
        })
    },
    {
        title: "在线教育",
        path: "/educationList",
        name: Loadable({
            loader: () => import('../views/Education/list'),
            loading: Loading
        })
    },
    {
        title: "在线教育 -- 详情",
        path: "/educationDetail/:id",
        name: Loadable({
            loader: () => import('../views/Education/detail'),
            loading: Loading
        })
    },
    {
        title: "支付界面",
        path: "/payment",
        name: Loadable({
            loader: () => import('../views/Payment'),
            loading: Loading
        })
    },
    {
        title: "公对公转账",
        path: "/transfer/:orderNo",
        name: Loadable({
            loader: () => import('../views/Payment/transfer'),
            loading: Loading
        })
    },
    {
        title: "支付完成",
        path: "/done",
        name: Loadable({
            loader: () => import('../views/Payment/done'),
            loading: Loading
        })
    },
    {
        title: "报名",
        path: "/signUpPage/:activityId",
        name: Loadable({
            loader: () => import('../views/SignUp/index'),
            loading: Loading
        })
    },
    {
        title: "直播详情",
        path: "/liveDetail/:id",
        name: Loadable({
            loader: () => import('../views/Live/index'),
            loading: Loading
        })
    }
];
