import {KY_Post, KY_Get} from "../utils/u_Request";

// 根据域名获取租户信息
export const GetTenantByDomain = (param = {}) => KY_Get('/contentMould/getTenantByDomain', param);

// 根据名称获取栏目列表
export const GetTypeListByName = (param = {}) => KY_Get('/contentMould/getTypeListByName', param);

// 前台获取内容列表
export const ViewList = (param = {}) => KY_Post('/contentMould/viewList', param);

// 前台获取展示详情
export const ViewDetail = (param = {}) => KY_Get('/contentMould/viewDetail', param);

// 前台获取内容前后2篇文章
// export const GetDetailAround = (param = {}) => KY_Get('/contentMould/getDetailAround', param);
export const GetDetailAround = (param = {}) => KY_Get('/contentMould/aroundContent', param);

// 批量下载附件
export const GetDownLoadFiles = (param = {}) => KY_Get('/contentMould/getDownLoadFiles', param);

// 获取期刊所有年份
export const GetFilesYears = (param = {}) => KY_Get('/contentMould/getFilesYears', param);

// 会议活动
export const ListVote = (param = {}) => KY_Post('/vote/public/listVote', param);

// 获取投票列表
export const ListInfo = (param = {}) => KY_Post('/activityinfo/public/listInfo', param);

// 获取学会动态封面图（首页），只取前四
export const GetSocietyNewImages = (param = {}) => KY_Get('/contentMould/getSocietyNewImages', param);

// 学会banner图
export const AdvertiseList = (param = {}) => KY_Post('/contentMould/advertiseList', param);

// 联系我们
export const GetTenantCom = (param = {}) => KY_Get('/company/getTenantCom', param);

// 根据租户信息获取前台广告位列表
export const GetAdPositionList = (param = {}) => KY_Post('/contentMould/getAdPositionList', param);

// 退出
export const LoginOut = (param = {}) => KY_Post('/userLogin/logOut',param);

// uv
export const SetIp = (param = {}) => KY_Get('/contentStaticRecord/setIp',param);